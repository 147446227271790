import * as React from "react";
import * as ReactDOM from "react-dom";

import "./main.css";
import styles from "./App.css";

interface Link {
  label: string;
  url: string;
}

const LINKS: Link[] = [
  {
    label: "cardindex",
    url: "//cardindex.stephenfrancis.online",
  },
  {
    label: "doorkey",
    url: "//doorkey.stephenfrancis.online",
  },
  {
    label: "dox",
    url: "//dox.stephenfrancis.online",
  },
  {
    label: "github",
    url: "//github.com/stephenfrancis",
  },
  {
    label: "lift-controller",
    url: "//lift-controller.stephenfrancis.online",
  },
  {
    label: "mancala",
    url: "/mancala.html",
  },
  {
    label: "playread",
    url: "//playread.stephenfrancis.online",
  },
  {
    label: "reversi",
    url: "/reversi.html",
  },
  {
    label: "trumps",
    url: "/trumps.html",
  },
  {
    label: "wordsolver",
    url: "//wordsolver.stephenfrancis.online",
  },
];

const App: React.FC<Record<never, never>> = () => {
  return (
    <>
      <div className={styles.fixed}>
        <div>stephen</div>
        <div>francis</div>
        <div>.online</div>
      </div>
      <nav>
        {LINKS.map((link, index) => (
          <a
            href={link.url}
            style={{
              color: `hsl(${Math.floor(
                (index * 360) / LINKS.length
              )}, 100%, 50%)`,
            }}
          >
            {link.label}
          </a>
        ))}
      </nav>
    </>
  );
};

const target = document.querySelector("#app");
ReactDOM.render(<App />, target);
